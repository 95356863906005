body {
  color: #efefef;
  z-index: 0;
  background-color: #111;
  font-size: 16px;
  position: relative;
}

noscript {
  text-align: center;
  padding: 2em 1em;
  font-size: 1.5rem;
  display: block;
}

#app {
  height: 100vh;
  z-index: 1;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

header {
  z-index: 5000;
  will-change: padding-bottom;
  padding-bottom: 15vmin;
  transition: padding-bottom .5s;
  position: relative;
}

header h1 {
  margin: 0 0 1.5vmin;
  padding: 0;
}

header #logo {
  width: 230px;
  height: 60px;
  max-width: 90%;
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  background-image: url("amphidom-logo.eeee842f.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .5s;
  display: inline-block;
}

header .slogan {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  font-family: serif;
  font-size: 1.2em;
  font-style: italic;
}

.background {
  will-change: transform;
  transition: transform .1s linear;
  position: absolute;
  inset: 0;
}

.background .inner {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  inset: 0;
}

.background.rendered .inner {
  visibility: visible;
  opacity: 1;
}

button.cta-quote {
  all: unset;
  color: #111;
  text-align: center;
  max-width: 95%;
  white-space: nowrap;
  cursor: pointer;
  background-color: #0cb5f2;
  border-radius: 2em;
  margin: 0;
  padding: .5em 1.2em;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  font-weight: 600;
  line-height: 130%;
  text-decoration: none;
  display: inline-block;
}

#layer-1 {
  z-index: 1;
}

#layer-1 .inner {
  transition: opacity 1.2s .3s;
}

#layer-2 {
  z-index: 2;
}

#layer-2 .inner {
  transition: opacity .8s .8s;
}

#layer-3 {
  z-index: 3;
}

#layer-3 .inner {
  transition: opacity 1s .5s;
}

.circle {
  z-index: 5;
  will-change: transform, opacity;
  position: absolute;
}

.circle.item-1 {
  width: 10vmin;
  height: 10vmin;
  opacity: .3;
  background: #0cb5f2;
  border-radius: 50%;
  animation: 5s linear infinite anime-1, 3s ease-in-out infinite opac-3-2;
  box-shadow: 0 0 2vmin 3vmin #0cb5f2;
}

.circle.item-2 {
  width: 20vmin;
  height: 20vmin;
  opacity: .4;
  background: #0cc8f2;
  border-radius: 50%;
  animation: 4s linear 1s infinite anime-2, 3.5s ease-in-out infinite opac-4-3;
  box-shadow: 0 0 4vmin 6vmin #0cc8f2;
}

.circle.item-3 {
  width: 18vmin;
  height: 18vmin;
  opacity: .2;
  background: #0c9af2;
  border-radius: 50%;
  animation: 5s linear infinite anime-3, 3s ease-in-out 1s infinite opac-2-1;
  box-shadow: 0 0 3.6vmin 5.4vmin #0c9af2;
}

.circle.item-4 {
  width: 28vmin;
  height: 28vmin;
  opacity: .2;
  background: #888;
  border-radius: 50%;
  animation: 4s linear infinite anime-4, 3s ease-in-out infinite opac-2-1;
  box-shadow: 0 0 5.6vmin 8.4vmin #888;
}

.circle.item-5 {
  width: 32vmin;
  height: 32vmin;
  opacity: .2;
  background: #87867f;
  border-radius: 50%;
  animation: 5s linear infinite anime-5, 3s ease-in-out 1s infinite opac-2-1;
  box-shadow: 0 0 6.4vmin 9.6vmin #87867f;
}

.light-spot {
  z-index: 5;
  will-change: transform, opacity;
  position: absolute;
}

.light-spot.top-1 {
  width: 11vmin;
  height: 11vmin;
  opacity: .8;
  background: #c2edfc99;
  border-radius: 50%;
  animation: 8s linear infinite flash, 5s linear infinite t-1;
  box-shadow: 0 0 .88vmin .55vmin #9de2fabf, inset -4px -4px 1.65vmin #9de2fa33;
}

.light-spot.top-2 {
  width: 12vmin;
  height: 12vmin;
  opacity: .8;
  background: #c8effc99;
  border-radius: 50%;
  animation: 10s linear 5s infinite flash, 6s linear infinite t-2;
  box-shadow: 0 0 .96vmin .6vmin #a7e5fbbf, inset -4px -4px 1.8vmin #a7e5fb33;
}

.light-spot.top-3 {
  width: 10vmin;
  height: 10vmin;
  opacity: .8;
  background: #c2fcf899;
  border-radius: 50%;
  animation: 12s linear 6s infinite flash, 5s linear 3s infinite t-2;
  box-shadow: 0 0 .8vmin .5vmin #9dfaf4bf, inset -4px -4px 1.5vmin #9dfaf433;
}

.light-spot.item-1 {
  width: 17vmin;
  height: 17vmin;
  background: #cee9fdb3;
  border-radius: 50%;
  animation: 15s linear 2s infinite blink, 5s linear infinite horz-1;
  box-shadow: 0 0 .34vmin .17vmin #85c7f9b3, inset -5px -5px 2.55vmin #6dbcf880;
}

.light-spot.item-2 {
  width: 15vmin;
  height: 15vmin;
  background: #cefcfdb3;
  border-radius: 50%;
  animation: 12s linear 2s infinite blink, 6s ease-in-out infinite horz-1;
  box-shadow: 0 0 .3vmin .15vmin #85f7f9b3, inset -5px -5px 2.25vmin #6df5f880;
}

.light-spot.item-3 {
  width: 16vmin;
  height: 16vmin;
  background: #ceecfdb3;
  border-radius: 50%;
  animation: 18s linear infinite blink, 5s linear infinite horz-3;
  box-shadow: 0 0 .32vmin .16vmin #85cff9b3, inset -5px -5px 2.4vmin #6dc5f880;
}

.light-spot.item-4 {
  width: 15vmin;
  height: 15vmin;
  background: #cef8fdb3;
  border-radius: 50%;
  animation: 20s linear 1s infinite blink, 6s linear infinite horz-4;
  box-shadow: 0 0 .3vmin .15vmin #85edf9b3, inset -5px -5px 2.25vmin #6deaf880;
}

.light-spot.item-5 {
  width: 16vmin;
  height: 16vmin;
  background: #fcfae8b3;
  border-radius: 50%;
  animation: 15s linear 2s infinite blink, 5s linear infinite horz-5;
  box-shadow: 0 0 .32vmin .16vmin #f8f3c6b3, inset -5px -5px 2.4vmin #f6f0ba80;
}

.light-spot.item-6 {
  width: 15vmin;
  height: 15vmin;
  background: #f8f7f8b3;
  border-radius: 50%;
  animation: 10s linear 3s infinite blink, 8s linear infinite horz-6;
  box-shadow: 0 0 .3vmin .15vmin #eeebeeb3, inset -5px -5px 2.25vmin #ebe6eb80;
}

.light-spot.item-7 {
  width: 14vmin;
  height: 14vmin;
  background: #cefafdb3;
  border-radius: 50%;
  animation: 15s linear 1s infinite blink, 8s linear infinite horz-7;
  box-shadow: 0 0 .28vmin .14vmin #85f1f9b3, inset -5px -5px 2.1vmin #6deff880;
}

@keyframes opac-4-3 {
  0% {
    opacity: .4;
  }

  50% {
    opacity: .3;
  }

  100% {
    opacity: .4;
  }
}

@keyframes opac-3-2 {
  0% {
    opacity: .3;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .3;
  }
}

@keyframes opac-2-1 {
  0% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  100% {
    opacity: .2;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  96% {
    opacity: 1;
  }

  98% {
    opacity: .65;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: .8;
  }

  91% {
    opacity: .8;
  }

  92% {
    opacity: .65;
  }

  94% {
    opacity: .9;
  }

  96% {
    opacity: 1;
  }

  98% {
    opacity: .95;
  }

  100% {
    opacity: .8;
  }
}

@keyframes anime-1 {
  0% {
    transform: translate3d(0, 0, 0)scale(1);
  }

  25% {
    transform: translate3d(1%, 5%, 0)scale(1.03);
  }

  50% {
    transform: translate3d(0, 3%, 0)scale(1.1);
  }

  75% {
    transform: translate3d(-2%, -2%, 0)scale(1.05);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes anime-2 {
  0% {
    transform: translate3d(0, 0, 0)scale(1);
  }

  25% {
    transform: translate3d(3%, 1%, 0)scale(1.05, 1);
  }

  50% {
    transform: translate3d(1%, 0, 0)scale(1.1, 1.02);
  }

  75% {
    transform: translate3d(-3%, -2%, 0)scale(1.03);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes anime-3 {
  0% {
    transform: translate3d(0, 0, 0)scale(1);
  }

  25% {
    transform: translate3d(-3%, -1%, 0)scale(1, 1.05);
  }

  50% {
    transform: translate3d(-1%, 0, 0)scale(1.05, 1.1);
  }

  75% {
    transform: translate3d(3%, 1%, 0)scale(1.02);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes anime-4 {
  0% {
    transform: translate3d(0, 0, 0)scale(1);
  }

  25% {
    transform: translate3d(-2%, -4%, 0)scale(1.02);
  }

  50% {
    transform: translate3d(0, -3%, 0)scale(1.1);
  }

  75% {
    transform: translate3d(2%, 2%, 0)scale(1.05);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes anime-5 {
  0% {
    transform: translate3d(0, 0, 0)scale(1);
  }

  25% {
    transform: translate3d(-1%, -2%, 0)scale(1.04);
  }

  50% {
    transform: translate3d(.5%, 1%, 0)scale(1.1);
  }

  75% {
    transform: translate3d(1%, .5%, 0)scale(1.03);
  }

  100% {
    transform: translate3d(0, 0, 0)scale(1);
  }
}

@keyframes horz-1 {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-2.5%, 0, 0);
  }

  50% {
    transform: translate3d(2%, 0, 0);
  }

  75% {
    transform: translate3d(1%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-2 {
  10% {
    transform: translate3d(0, 0, 0);
  }

  30% {
    transform: translate3d(3%, 0, 0);
  }

  50% {
    transform: translate3d(-4%, 0, 0);
  }

  80% {
    transform: translate3d(-1%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-3 {
  10% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-2%, 0, 0);
  }

  50% {
    transform: translate3d(4%, 0, 0);
  }

  75% {
    transform: translate3d(2%, 0, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-4 {
  15% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-2.5%, 0, 0);
  }

  50% {
    transform: translate3d(2%, 0, 0);
  }

  75% {
    transform: translate3d(1%, 0, 0);
  }

  80% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-5 {
  30% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(3%, 0, 0);
  }

  50% {
    transform: translate3d(2%, 0, 0);
  }

  80% {
    transform: translate3d(-1%, 0, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-6 {
  20% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-4%, 0, 0);
  }

  60% {
    transform: translate3d(-3%, 0, 0);
  }

  75% {
    transform: translate3d(-1%, 0, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes horz-7 {
  20% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(8%, 0, 0);
  }

  50% {
    transform: translate3d(5%, 0, 0);
  }

  70% {
    transform: translate3d(3%, 0, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes t-1 {
  10% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(-2.5%, 0, 0);
  }

  50% {
    transform: translate3d(1.8%, 0, 0);
  }

  75% {
    transform: translate3d(.5%, 0, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes t-2 {
  15% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(1%, -.8%, 0);
  }

  50% {
    transform: translate3d(1.5%, -2%, 0);
  }

  70% {
    transform: translate3d(.3%, -1%, 0);
  }

  85% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes t-3 {
  25% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(1.5%, -1.8%, 0);
  }

  60% {
    transform: translate3d(-.8%, -.8%, 0);
  }

  75% {
    transform: translate3d(-.2%, .5%, 0);
  }

  80% {
    transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=index.afce5901.css.map */
