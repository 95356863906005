body
  background-color: #111
  color: #efefef
  position: relative
  z-index: 0
  font-size: 16px

noscript
  display: block
  text-align: center
  padding: 2em 1em
  font-size: 1.5rem

#app
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 100vh

  z-index: 1
  overflow: hidden
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  text-align: center

@import "vars.styl"

header
  position: relative
  z-index: 5000
  padding-bottom: 15vmin
  transition: padding-bottom .5s
  will-change: padding-bottom

  h1
    margin: 0 0 1.5vmin 0
    padding: 0

  #logo
    display: inline-block
    width: 230px
    height: 60px
    max-width: 90%
    background-image: url("../assets/amphidom-logo.svg")
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    opacity: 1
    transition: opacity .5s
    user-select: none
    // cursor: pointer
    // &:active
    //   opacity: 0.5

  .slogan
    font-family: serif
    font-size: 1.2em
    font-style: italic
    pointer-events: none
    user-select: none
  
  // .button-wrapper
  //   visibility: hidden
  //   opacity: 0
  //   position: absolute
  //   top: 15vmin
  //   left: 0
  //   right: 0
  //   text-align: center

  // &.active
  //   padding-bottom 30vmin

  //   .button-wrapper
  //     visibility: visible
  //     opacity: 1

.background
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  will-change: transform
  transition: transform 0.1s linear
  .inner
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    visibility: hidden
    opacity: 0
  &.rendered
    .inner
      visibility: visible
      opacity: 1

button.cta-quote
  all: unset
  font-family: Helvetica, Arial, sans-serif
  display: inline-block
  background-color: #0CB5F2
  color: #111
  text-align: center
  text-decoration: none
  font-size: 1.3em
  font-weight: 600
  line-height: 130%
  max-width: 95%
  border-radius: 2em
  padding: 0.5em 1.2em
  margin: 0
  white-space: nowrap
  cursor: pointer

#layer-1
  z-index: 1
  .inner
    transition: opacity 1.2s .3s
#layer-2
  z-index: 2
  .inner
    transition: opacity .8s .8s
#layer-3
  z-index: 3
  .inner
    transition: opacity 1s .5s

.circle
  position: absolute
  z-index: 5
  will-change: transform, opacity

  &.item-1
    circle(10vmin, $branding-primary)
    opacity: 0.3
    animation: anime-1 5s linear 0s infinite,
               opac-3-2 3s ease-in-out 0s infinite

  &.item-2
    circle(20vmin, spin($branding-primary, -5deg))
    opacity: 0.4
    animation: anime-2 4s linear 1s infinite,
               opac-4-3 3.5s ease-in-out 0s infinite

  &.item-3
    circle(18vmin, spin($branding-primary, 7deg))
    opacity: 0.2
    animation: anime-3 5s linear 0s infinite,
               opac-2-1 3s ease-in-out 1s infinite

  &.item-4
    circle(28vmin, #888)
    opacity: 0.2
    animation: anime-4 4s linear 0s infinite,
               opac-2-1 3s ease-in-out 0s infinite

  &.item-5
    circle(32vmin, #87867f)
    opacity: 0.2
    animation: anime-5 5s linear 0s infinite,
               opac-2-1 3s ease-in-out 1s infinite

.light-spot
  position: absolute
  z-index: 5
  will-change: transform, opacity

  &.top-1
    blurredLightSpot(11vmin, $branding-primary)
    opacity: 0.8
    animation: flash 8s linear 0s infinite,
               t-1 5s linear 0s infinite
  &.top-2
    blurredLightSpot(12vmin, lighten($branding-primary, 10%))
    opacity: 0.8
    animation: flash 10s linear 5s infinite,
               t-2 6s linear 0s infinite
  &.top-3
    blurredLightSpot(10vmin, spin($branding-primary, -20deg))
    opacity: 0.8
    animation: flash 12s linear 6s infinite,
               t-2 5s linear 3s infinite

  &.item-1
    lightSpot(17vmin, spin($branding-primary, 10deg))
    animation: blink 15s linear 2s infinite,
               horz-1 5s linear 0s infinite

  &.item-2
    lightSpot(15vmin, spin($branding-primary, -15deg))
    animation: blink 12s linear 2s infinite,
               horz-1 6s ease-in-out 0s infinite

  &.item-3
    lightSpot(16vmin, spin($branding-primary, 6deg))
    animation: blink 18s linear 0s infinite,
               horz-3 5s linear 0s infinite

  &.item-4
    lightSpot(15vmin, spin($branding-primary, -10deg))
    animation: blink 20s linear 1s infinite,
               horz-4 6s linear 0s infinite

  &.item-5
    lightSpot(16vmin, khaki)
    animation: blink 15s linear 2s infinite,
               horz-5 5s linear 0s infinite

  &.item-6
    lightSpot(15vmin, #ddd6dd)
    animation: blink 10s linear 3s infinite,
               horz-6 8s linear 0s infinite

  &.item-7
    lightSpot(14vmin, spin($branding-primary, -12deg))
    animation: blink 15s linear 1s infinite,
               horz-7 8s linear 0s infinite

@import "animations.styl"
